import { type VariantProps, cva } from 'class-variance-authority'

export { default as Badge } from './Badge.vue'

export const badgeVariants = cva(
    'inline-flex items-center rounded-full border border-neutral-200 px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none',
    {
        variants: {
            variant: {
                default:
                    'border-transparent bg-neutral-900 text-neutral-50 hover:bg-neutral-900/80 dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/80',
                secondary:
                    'border-transparent bg-neutral-100 text-neutral-900 dark:bg-[#202226] dark:text-neutral-50',
                destructive:
                    'border-transparent bg-red-500 text-white',
                success:
                    'border-transparent bg-green-500 text-white',
                outline: 
                    'text-neutral-950 dark:text-neutral-50',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
)

export type BadgeVariants = VariantProps<typeof badgeVariants>
